/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/buidl.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Utils from "../../../utils"
import Keys from '../../../constants/keys'
import { navigate } from "gatsby"

class CreateOrg extends React.Component {
  constructor() {
    super();
    this.state = {message: "", promocode: ""}
  }
  render() {
    const handleChangeUpper = (e) => {
      let state = {};
      state[e.target.name] = e.target.value.toUpperCase();
      this.setState(state)
    }
    let location = this.props.location;
    const handleSubmit = (e) => {
      e.preventDefault()
      let target = e.target
      if(target.elements.orgname.value == 0) {
        this.setState({message: "Please provide an organization name"})
        return
      }
      let referral_code = ""
      if(typeof window != "undefined" && window) {
        referral_code = window.localStorage.getItem("referral_code");
      }
      return fetch("/api/org/new", {
        method: "POST",
        body: JSON.stringify({
          "org": target.elements.orgname.value,
          "promo_code": target.elements.promocode.value,
          "referral_code": referral_code,
        }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Utils.getCookie('csrftoken'),
        }
      }).then(function(response) { return response.json() }).then((data) => {
        if(data.ok) {
          if(typeof(dataLayer) == 'object') {
            dataLayer.push({'event': 'web3-signup-complete'});
          }
          navigate(`/dashboard`);
        } else if (data.error == "bad-promo") {
          this.setState({message: "The promo code you entered is not available. Double check and try again."});
        } else {
          this.setState({message: "An unknown error has occurred"});
        }
        return
      });
    }
    return (
      <section className={styles.buidl}>
      <span className={styles.modalHeader}><Title title="Organization setup:" subtitle="Complete this form." /></span>
      <p className={styles.instructionsModal}>Please provide a name for your organization or project</p>
      <div className={styles.center}>
      <form className={styles.form} onSubmit={handleSubmit}>
      <div>
      <input
      type="input"
      name="orgname"
      id="orgname"
      placeholder="organization"
      className={styles.formControl}
      />
      </div>
      <div className={styles.arrowButton}>
      <input
      type="submit"
      value="submit"
      className={styles.submit}
      action="/buidl"
      />
      </div>
      <details>
        <summary>Have a promo code?</summary>
        <article>
        <input type="input" value={this.state.promocode} onChange={handleChangeUpper} name="promocode" id="promocode" placeholder="promo code" className={styles.formControl} />
        </article>
      </details>
      </form>
      </div>
      </section>
    )
  }

}

export default CreateOrg
